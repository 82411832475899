import React from 'react';
import { graphql } from 'gatsby';
import { getImageUrl } from '@takeshape/routing';

import Layout from '../components/Layout';

import BlockHelloBar from '../components/Blocks/BlockHelloBar';
import BlockTypeformWidget from '../components/Blocks/BlockTypeformWidget';

const SuccessPage = ({
  data: {
    takeshape: { getSuccessPageList, getLandingPage },
  },
}) => {
  const successPageData = getSuccessPageList?.items[0];
  const blockHeroData = successPageData?.relationship?.content.find(
    (item) => item.__typename === 'TS_HeroBlock'
  );

  return successPageData && blockHeroData ? (
    <Layout
      title={successPageData.titleTag}
      description={successPageData.metaDescription}
      seo={getLandingPage.seo}
    >
      <BlockHelloBar {...successPageData?.relationship.floatingCta} />
      <div id="primary">
        <main id="main" className="container">
          <div className="entry-content">
            <div className="row">
              <div className="entry-content-outer col-12 pos-rel">
                <div className="entry-inner page type-page status-publish hentry">
                  <div className="entry-inner-content">
                    <div className="wp-block-atomic-blocks-ab-columns collapse-tablet ab-layout-columns-2 ab-2-col-equal">
                      <div
                        className="ab-layout-column-wrap ab-block-layout-column-gap-1 ab-is-responsive-column"
                        style={{ marginBottom: '1em' }}
                      >
                        <section className="wp-block-atomic-blocks-ab-column ab-block-layout-column hero-block-image">
                          <img
                            src={getImageUrl(blockHeroData.heroImage.path, {
                              q: 50,
                              fm: 'webp',
                            })}
                            alt={successPageData.pageTitle}
                            loading="lazy"
                          />
                        </section>
                        <div className="text-center">
                          {successPageData.pageTitle && (
                            <h1 className="entry-title">{successPageData.pageTitle}</h1>
                          )}
                          <div dangerouslySetInnerHTML={{ __html: successPageData.contentHtml }} />
                          <div>
                            {successPageData.cta?.text && successPageData.cta?.link && (
                              <a
                                className="btn btn--yellow btn--radius-sm"
                                href={successPageData.cta.link}
                              >
                                {successPageData.cta.text}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="wp-block-separator" />
                    <BlockTypeformWidget {...successPageData?.relationship.footerForm} />
                    <hr className="wp-block-separator" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  ) : null;
};

export const query = graphql`
  query SuccessPage($landingID: ID!, $landingID_String: String) {
    takeshape {
      getLandingPage(_id: $landingID) {
        seo {
          headScripts {
            embedCode
          }
        }
      }

      getSuccessPageList(where: { relationship: { _id: { eq: $landingID_String } } }) {
        items {
          pageTitle
          contentHtml
          metaDescription
          titleTag
          cta {
            text
            link
          }
          relationship {
            floatingCta {
              textHtml
              button {
                text
                link
              }
            }
            content {
              ... on TS_HeroBlock {
                __typename
                heroImage {
                  path
                }
              }
            }
            footerForm {
              embedCode
              embedTag
            }
          }
        }
      }
    }
  }
`;

export default SuccessPage;
